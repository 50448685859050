import React from 'react';
import GratitudeComponent from '../components/GratitudeComponent';

const Gratitude = () => {
    return (
        <> <GratitudeComponent /> </>
    );
}

export default Gratitude;
